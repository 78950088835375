<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="LockIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Internal Information</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col sm:w-full md:w-1/2 mt-6">
            <div class="mt-4 sz-test">
              <label class="text-sm">Role</label>
              <v-select :closeOnSelect="true" :options="roles" name="role" v-model="roles_selected" v-validate="'required'"/>
              <span class="text-danger text-sm" v-show="errors.has('role')">
                {{ errors.first('role') }}
              </span>
            </div>

          </div>

          <div class="vx-col w-1/2 mt-6">


          </div>
        </div>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.roles_selected = this.roles.filter(a => a.id === this.data_local.role)[0];
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),
      roles_selected: {id: '', label: ''},
      roles: [
        {id: 'user', label: 'User'},
        {id: 'employee', label: 'Employee'},
        {id: 'support_t1', label: 'Support T-1'},
        {id: 'support_t2', label: 'Support T-2'},
        {id: 'support_t3', label: 'Support T-3'},
        {id: 'editor', label: 'Editors'},
        {id: 'analyst', label: 'Analyst'},
        {id: 'proctor', label: 'Proctor'},
        {id: 'accountant', label: 'Accountant'},
        {id: 'dev', label: 'Developer'},
        {id: 'sys_admin', label: 'Sys-Admin'},
        {id: 'admin', label: 'Administrator'},
      ],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    initiateUserUpdate() {

      if (!this.roles_selected.id) {
        return this.$vs.notify({
          title: 'Failed to update',
          text: 'You must select a role.',
          color: 'danger',
        });
      }

      const payload = {role: this.roles_selected.id};

      this.$vs.loading();

      this.$http.post(`users/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            this.data_local.gender = this.data_local.gender.toLowerCase();

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>

<style>
.sz-test {
  min-height: 360px;
}
</style>
