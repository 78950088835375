<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">

    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="data_local.photoURL" class="mr-8 rounded h-24 w-24"/>
          <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
          <div>
            <input @change="selectFile" class="d-none pb-2 mt-3" id="file" ref="file" type="file"><br/>
            <vs-button @click="submitPicture" class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button>
            <vs-button @click="removePicture" color="danger" type="border">Remove</vs-button>
            <p class="text-sm mt-2">Allowed JPG or PNG. Max size of 2MB</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input class="w-full mt-4" label="First Name" name="first_name" tabindex="1" v-model="data_local.first_name"
                  v-validate="'required|alpha_num'"/>
        <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

        <vs-input class="w-full mt-4" label="Email" name="email" tabindex="3" type="email" v-model="data_local.email"
                  v-validate="'required|email'"/>
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>


      </div>

      <div class="vx-col md:w-1/2 w-full test">

        <vs-input class="w-full mt-4" label="Last Name" name="last_name" tabindex="2" v-model="data_local.last_name"
                  v-validate="'required|alpha_num'"/>
        <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
        <vs-input class="w-full mt-4" label="Company" name="company" tabindex="4" v-model="data_local.company"/>
        <span class="text-danger text-sm" v-show="errors.has('company')">{{ errors.first('company') }}</span>


      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data))
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    selectFile() {
      const files = this.$refs.file.files;

      if (files.length > 0) {
        this.file = files[0];
      }

    },
    initiateUserUpdate() {

      const payload = {
        first_name: this.data_local.first_name,
        last_name: this.data_local.last_name,
        email: this.data_local.email,
        company: this.data_local.company,
      };

      this.$vs.loading();

      this.$http.post(`users/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            this.data_local.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${response.data.data.profile_picture}.jpeg`;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          if (exception.response) {
            const response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-user-x',
            color: 'danger',
          });
        });
    },
    removePicture() {

      this.$vs.loading();

      this.$http.delete(`users/${this.data.id}/picture`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            if (response.data.data.profile_picture) {
              this.data_local.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${response.data.data.profile_picture}.jpeg`;
            }

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s profile picture has been removed.`,
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });
    },
    submitPicture() {

      if (typeof this.file !== 'object') {
        return this.$vs.notify({
          title: 'Error',
          text: 'You must select a file to upload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
      this.$vs.loading();

      const formData = new FormData();
      formData.append('upload', this.file);

      this.$http.post(`users/${this.data.id}/picture`, formData)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            if (response.data.data.profile_picture) {
              this.data_local.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${response.data.data.profile_picture}.jpeg`;
            }

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s' settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });

    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>
