<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="user-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="UserIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Personal Information</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col w-1/2 mt-6">

            <!-- DOB -->
            <div class="mt-4">
              <label class="text-sm">Birth Date</label>
              <flat-pickr :config="{ dateFormat: 'd F Y', maxDate: new Date() }" class="w-full" name="dob"
                          v-model="data_local.dob" v-validate="'required'"/>
              <span class="text-danger text-sm" v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
            </div>

            <vs-input class="w-full mt-4" label="Mobile" name="mobile" v-model="data_local.phone_number"/>
            <span class="text-danger text-sm" v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>

            <vs-input class="w-full mt-4" data-vv-as="field" label="Website"
                      name="website" v-model="data_local.website" v-validate="'url:require_protocol'"/>
            <span class="text-danger text-sm" v-show="errors.has('website')">{{ errors.first('website') }}</span>


          </div>

          <div class="vx-col w-1/2 mt-6">
            <div class="mt-4">
              <label class="text-sm">Language</label>
              <v-select :closeOnSelect="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" :options="langOptions"
                        name="language" v-model="data_local.language" v-validate="'required'"/>
              <span class="text-danger text-sm" v-show="errors.has('lang_known')">{{
                  errors.first('lang_known')
                }}</span>
            </div>

            <!-- Gender -->
            <div class="mt-4">
              <label class="text-sm">Gender</label>
              <div class="mt-2">
                <vs-radio class="mr-4" v-model="data_local.gender" vs-value="male" @input="custom_gender = false">Male</vs-radio>
                <vs-radio class="mr-4" v-model="data_local.gender" vs-value="female" @input="custom_gender = false">Female</vs-radio>
                <vs-radio v-model="data_local.gender"  @input="custom_gender = true">Other</vs-radio>

                <vs-input  class="w-full mt-4" data-vv-as="field" label="Gender"
                          name="gender" v-model="data_local.gender"/>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
          <vs-button @click="resetData" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

      data_local: JSON.parse(JSON.stringify(this.data)),
      custom_gender: false,

      langOptions: [
        {label: 'English', value: 'english'},
        {label: 'Spanish', value: 'spanish'},
        {label: 'French', value: 'french'},
        {label: 'Russian', value: 'russian'},
        {label: 'German', value: 'german'},
        {label: 'Arabic', value: 'arabic'},
        {label: 'Sanskrit', value: 'sanskrit'},
      ],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    initiateUserUpdate() {


      const payload = {
        dob: this.data_local.dob,
        phone_number: this.data_local.phone_number,
        website: this.data_local.website,
        language: this.data_local.language.value || 'english',
        gender: this.data_local.gender,
      };

      this.$vs.loading();

      this.$http.post(`users/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            this.data_local.gender = this.data_local.gender.toLowerCase();

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s settings have been updated`,
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          if (exception.response) {
            const response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-user-x',
            color: 'danger',
          });
        });
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>
