<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert :active.sync="user_not_found" color="danger" title="User Not Found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/a/users" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="user_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-user" icon-pack="feather" label="Account">
            <div class="tab-text">
              <user-edit-tab-account :data="user_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-info" icon-pack="feather" label="Information">
            <div class="tab-text">
              <user-edit-tab-information :data="user_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab v-if="$acl.check('admin')" icon="icon-lock" icon-pack="feather" label="Internal">
            <div class="tab-text">
              <user-edit-tab-internal :data="user_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import UserEditTabAccount from './UserEditTabAccount.vue';
import UserEditTabInformation from './UserEditTabInformation.vue';
import UserEditTabInternal from './UserEditTabInternal.vue';

// Store Module

export default {
  components: {
    UserEditTabInternal,
    UserEditTabAccount,
    UserEditTabInformation,
  },
  data() {
    return {
      user_data: null,
      user_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchUserData(this.$route.params.userId);
    },
  },
  methods: {
    fetchUserData(userId) {
      this.$http.get(`users/${userId}`)
        .then(response => {

          if (response.data.data) {
            const data = response.data.data;
            this.user_data = data;
            this.user_data.gender = data.gender.toLowerCase();

            const pictureId = data.profile_picture || 'default';
            this.user_data.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
          }

        })
        .catch(error => {
          if (error.response.status === 404) {
            this.user_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    this.fetchUserData(this.$route.params.userId);
  },
};

</script>
